<!-- 站内信 -->
<template>
  <div style="height: 100%">
    <el-button class="returnBtn" @click="returnBtn" icon="el-icon-back">返回</el-button>
    <el-button
      class="returnBtn"
      type="primary"
      v-if="writeMail"
      icon="el-icon-s-promotion"
      @click="sendMessageVerification"
    >发送</el-button>
    <el-button
      type="primary"
      icon="el-icon-edit"
      class="write-letters"
      @click="writeLetters"
      v-if="!mailDetails && !writeMail"
    >写信</el-button>
    <el-card class="card-mail" v-if="!mailDetails && !writeMail">
      <el-divider></el-divider>
      <ul style="height: 680px">
        <el-scrollbar style="height: 100%">
          <li v-for="(item, index) in educationMail" :key="index" @click="clickMail(item)">
            <span>
              <i
                :style="
                  item.state == 0
                    ? 'color:red;font-size:20px'
                    : 'font-size:20px'
                "
                class="el-icon-message"
              ></i>
            </span>
            <span :class="item.state == 0 ? 'fw7' : 'fw4'">
              {{
              item.title
              }}
            </span>
            <span style="color: #a0a0a0">{{ item.content }}</span>
            <span :class="item.state == 0 ? 'fw7' : 'fw4'" style="margin: 0">
              {{
              item.time
              }}
            </span>
            <span @click.stop="delMail(item)">
              <i class="el-icon-delete" style="color: red"></i>
            </span>
            <el-divider></el-divider>
          </li>
        </el-scrollbar>
      </ul>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="mailTotal"
        :current-page="mailCurrent"
        :page-size="pagesize"
        @current-change="mailData"
      ></el-pagination>
    </el-card>
    <!-- 信件详情 -->
    <el-card v-if="mailDetails" class="mail-details">
      <p>{{ mailContent.mailName }}</p>
      <p>发件人：{{ mailContent.sendFull + "---" + mailContent.sendName }}</p>
      <p>时&ensp;&ensp;间：{{ mailContent.time1 }}</p>
      <p>收件人：{{ mailContent.receiveFull + "---" + mailContent.receiveName }}</p>
      <el-divider></el-divider>
      <p class="mail-content">{{ mailContent.content }}</p>
      <el-divider></el-divider>
      <div class="reply-div">
        <el-input placeholder="快点回复Ta吧！" v-model="writeReply"></el-input>
        <el-button type="primary" @click="replyMail">回复</el-button>
      </div>
    </el-card>
    <!-- 写信 -->
    <el-card v-if="writeMail" class="write-details">
      <div class="addressee">
        <p>收件人：</p>
        <el-input v-model="writeAddressee"></el-input>
      </div>
      <div class="theme">
        <p>主&ensp;&ensp;题：</p>
        <el-input v-model="writeTheme"></el-input>
      </div>
      <div class="text">
        <p>正&ensp;&ensp;文：</p>
        <textarea v-model="writeContent"></textarea>
      </div>
      <p>发件人：{{ fullName + "---" + userName }}</p>
    </el-card>
  </div>
</template>

<script>
import { messageAdd } from "../../api/api";
import { messageList } from "../../api/api";
import { messageUpdate } from "../../api/api";
import { messageRemove } from "../../api/api";
import mqtt from "mqtt";
import { locale } from 'moment';

export default {
  data () {
    return {
      mailTotal: 0,//信息条数
      mailCurrent: 1,//分页页码
      pagesize: 15,//显示条数
      fullName: null,//收件人名称(或者同下)
      userName: null,//收件人账号(或者同上)
      educationMail: [],//信息列表
      mailDetails: false,//是否展示信息详情
      writeMail: false,//是否显示写信页面
      mailContent: {},//信件详情集合
      writeContent: "", //信件正文
      writeAddressee: "",//收件人
      writeTheme: "",//主题
      writeReply: "",//回复内容
    };
  },

  components: {},
  created () {
    this.analysis();
  },
  mounted () {
    this.loadMessage();
  },
  methods: {
    // 分页
    mailData (val) {
      this.mailCurrent = val
      this.loadMessage()
    },
    // 删除信件
    delMail (val) {
      this.$confirm("您确定删除该邮件吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          arr.push(val.id);
          messageRemove({ ids: arr }).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.loadMessage();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除！",
          });
        });
    },
    // 回复
    replyMail () {
      let val = {
        writeAddressee: this.mailContent.sendName,
        writeTheme: this.mailContent.sendFull + "回复",
        writeContent: this.writeReply,
      };
      if (val.writeContent == "") {
        this.$message.error("回复内容不能为空！");
      } else {
        this.sendMessage(val);
      }
    },
    // 发送信息验证
    sendMessageVerification () {
      if (this.writeAddressee) {
        if (this.writeContent && this.writeTheme) {
          let val = {
            writeAddressee: this.writeAddressee,
            writeTheme: this.writeTheme,
            writeContent: this.writeContent,
          };
          this.sendMessage(val);
        } else {
          this.$confirm("您的信件主题或正文为空，是否继续发送？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let val = {
                writeAddressee: this.writeAddressee,
                writeTheme: this.writeTheme,
                writeContent: this.writeContent,
              };
              this.sendMessage(val);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消发送",
              });
            });
        }
      } else {
        this.$message.error("您还未填写收件人！");
      }
    },
    // 发送信息
    sendMessage (val) {
      let param = {
        receiveName: val.writeAddressee,
        title: val.writeTheme,
        content: val.writeContent,
      };
      messageAdd(param).then((res) => {
        if (res.code == 0) {
          //站内信发消息

          this.$message({
            type: "success",
            message: "发送成功!",
          });
          this.writeAddressee = "";
          this.writeReply = "";
          this.writeTheme = "";
          this.writeContent = "";
          this.writeMail = false;
        } else {
          this.$message.error('收件人信息错误！');
        }
      });
    },
    // 信息列表
    loadMessage () {
      messageList({ pageNum: this.mailCurrent, pageSize: this.pagesize }).then((res) => {
        if (res.data) {
          res.data.list.map((item) => {
            item.time = this.rTime(item.createDate).split(" ")[0].split("-");
            item.time1 = this.rTime(item.createDate).split(" ")[1];
            item.time = item.time[1] + "-" + item.time[2];
            item.time1 = item.time + " " + item.time1;
          });
          this.mailTotal = res.data.total
          this.educationMail = res.data.list;
        }
      });
    },
    // 解析appIds
    analysis () {
      var urlAppId = window.location.href.split("=")[2].split("#")[0];
      let appIds = JSON.parse(localStorage.getItem("appIds"));
      if (appIds) {
        let target = appIds.find(item => { return item.appId === urlAppId })
        this.fullName = target.fullName;
        this.userName = target.userName;
        this.appIdUser = target.userId;
      }
    },
    writeLetters () {
      this.writeMail = true;
    },
    clickMail (val) {
      let param = {
        id: val.id,
      };
      messageUpdate(param).then((res) => { });
      this.mailContent = val;
      this.mailDetails = true;
    },
    returnBtn () {
      if (this.mailDetails) {
        this.mailDetails = false;
        this.loadMessage();
      } else if (this.writeMail) {
        this.writeMail = false;
        this.loadMessage();
      } else {
        this.$router.go(-1);
      }
    },
    // 时间转换
    rTime (date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },

  },
};
</script>
<style lang='less' scoped>
@import '../../style/educationMail/index.less';
@import '../../style/mouse.less';
.fw7 {
  font-weight: 700;
}
.fw4 {
  font-weight: 400;
}
.el-pagination {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin: 10px 0;
}
</style>
